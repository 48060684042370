<template>
  <modal :title="this.$t('pre_release')" :stylebody="'var(--vz-body-bg)'">
    <template v-slot:head-bottom>
      <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')">
        <i class="ri-close-fill align-bottom"></i>
      </button>
    </template>
    <template v-slot:body>
      <b-row class='nav nav-pills flex-column flex-sm-row'>
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" @change="changeTab('projects')" v-if="this.projectShow == true" checked>
          <label class="btn btn-outline-primary" for="btnradio1" v-if="this.projectShow == true">{{ $t('navProjects') }}</label>

          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" @change="changeTab('tickets')" v-if="this.ticketShow == true">
          <label class="btn btn-outline-primary" for="btnradio2" v-if="this.ticketShow == true">{{ $t('tickets') }}</label>

          <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" @change="changeTab('success')" :disabled="this.tab != 'success'" :checked="this.ticketShow == false && this.projectShow == false">
          <label class="btn btn-outline-primary" for="btnradio3">{{ $t('toComplete') }}</label>
        </div>
      </b-row>
      <b-row>
        <component :is="this.tab" :tickets="this.itemsTickets" :projects="this.items" @boxTicketsRel="ticketsRel" @boxProjectsRel="projectsRel" :id="this.id" @close="this.$emit('close')" :key="compkey" :child="this.deadlines" :form="this.form" @getdata="this.$emit('getdata')"></component>
      </b-row>
    </template>
    <template v-slot:footer-bottom>
      <button type="button"  class="btn btn-danger" v-if="this.count == 2" @click="this.$emit('close')">{{ $t('done') }}</button>
    </template>
  </modal>
  

  <dialogBox 
    v-if="boxProjectRel"
    @close="boxProjectRel = false"
  >
    <template v-slot:body>
      <div class="mt-4 text-center">
          <h3><b>{{ $t('ConfirmChangesProjects') }}</b></h3>
          <p class="text-muted fs-15 mb-4">{{ $t('ConfirmChangesDesc') }}</p>
      </div>
    </template>
    <template v-slot:footer-bottom>
      <div class="hstack gap-2 mt-3 justify-content-center remove">
          <button class="btn btn-danger" @click="boxProjectRel = false">{{ $t('n') }}</button>
          <button class="btn btn-success" @click="finishTheServer('projects')">{{ $t('y') }}</button>
      </div>
    </template>
  </dialogBox>

  <dialogBox 
    v-if="boxTicketsRel"
    @close="boxTicketsRel = false"
  >
    <template v-slot:body>
      <div class="mt-4 text-center">
          <h3><b>{{ $t('ConfirmChangesTickets') }}</b></h3>
          <p class="text-muted fs-15 mb-4">{{ $t('ConfirmChangesDesc') }}</p>
      </div>
    </template>
    <template v-slot:footer-bottom>
      <div class="hstack gap-2 mt-3 justify-content-center remove">
          <button class="btn btn-danger" @click="boxTicketsRel = false">{{ $t('n') }}</button>
          <button class="btn btn-success" @click="finishTheServer('tickets')">{{ $t('y') }}</button>
      </div>
    </template>
  </dialogBox>

</template>

<script>
import success from './deleteCateg/success.vue'
import tickets from './deleteCateg/tickets.vue';
import projects from './deleteCateg/projects.vue';
import dialogBox from '@/components/modal/dialog'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import modal from '@/components/modal'
import { Updates } from '@/API.js';
import { storeS } from '@/store.js'

let apiServe = new Updates();

export default {
  data() {
    return {
      deadlines: [],
      tab: 'projects',
      ticketShow: true,
      projectShow: true,
      count: 0,
      delete: [],
      transfer: [],
      child: [],
      boxProjectRel: false,
      boxTicketsRel: false,
      status: {
        projects: false,
        tickets: false
      },
      compkey: 0,
      transferProjects: [],
      transferTickets: [],
      projectsInRelease: [],
      ticketsInRelease: []
    };
  },
  props: ['items', 'itemsTickets', 'objdata', 'id', 'form'],
  components: {
    modal,
    Multiselect,
    dialogBox,
    tickets,
    projects,
    success
  },
  created() {
    this.getUpdates()
    if(this.items.length == 0) {
      this.projectShow = false
    } 
    if(this.itemsTickets.length == 0) {
      this.ticketShow = false
    }
    console.log(this.items, this.objdata, this.itemsTickets)
    this.objdata.forEach(element => {
      this.child.push({label: element.update + ' ' + element.version, value: element.deadlineId})
    });
  },
  methods: {
    projectsRel(e, e1) {
      this.projectsInRelease = e1
      this.transferProjects = e
      this.boxProjectRel = true
    },
    ticketsRel(e, e1) {
      this.ticketsInRelease = e1
      this.transferTickets = e
      this.boxTicketsRel = true
    },
    getUpdates() {
      let obj = {
        pagelimit: 100
      }
      apiServe.getAllUpdates(obj).then(result => {
        if(result.status == 'done') {
          result.data.items.forEach(el => {
            console.log(el, this.form)
            if(el.update == this.form.update && (new Date().getTime() - new Date(el.deadlineDate + " 23:59").getTime()) <= 0) {
              this.deadlines.push(el)
            }
          })
        }
      })  
    },
    changeTab(a) {
      this.tab = a
      this.compkey += 1 
    },
    finishTheServer(e) {
      if(e == 'projects') {
        let obj = {
          transferProjects: [],
          projectsInRelease: []
        }
        

        if(this.transferProjects.length > 0) {
          this.transferProjects.forEach(el => {
            obj.transferProjects.push({deadlineId: el.deadlineId, projectId: el.projectId})
          })
        }

        
        if(this.projectsInRelease.length > 0) {
          this.projectsInRelease.forEach(el => {
            obj.projectsInRelease.push(el.projectId)
          })
        }

        console.log('projects',obj.transferProjects, obj.projectsInRelease)
        apiServe.saveChangesProj(this.id, obj).then(result => {
          if(result.status == 'done') {
            this.$toast.success(this.$t('success'))
              this.count++
                this.projectShow = false
                this.boxProjectRel = false
                if(this.ticketShow == true) {
                  this.tab = 'tickets'
                } else {
                  this.tab = 'success'
                }
          } else {
            this.$toast.error(this.$t('error'))
          }
        })
      } else {
        let obj = {
          transferTickets: [],
          ticketsInRelease: []
        }

        if(this.transferTickets.length > 0) {
          this.transferTickets.forEach(el => {
            obj.transferTickets.push({deadlineId: el.deadlineId, ticketId: el.ticketId})
          })
        }

        if(this.ticketsInRelease.length > 0) {
          this.ticketsInRelease.forEach(el => {
            obj.ticketsInRelease.push(el.ticketId)
          })
        }

        console.log('tickets',obj.ticketsInRelease, obj.transferTickets)
        apiServe.saveChangesTick(this.id, obj).then(result => {
          if(result.status == 'done') {
            this.$toast.success(this.$t('success'))
            this.count++
            this.ticketShow = false
            this.boxTicketsRel = false
            if(this.projectShow == true) {
              this.tab = 'projects'
            } else {
              this.tab = 'success'
            }
          } else {
            this.$toast.error(this.$t('error'))
          }
        })
      }
    }
  },
  computed: {
    user(){
      return storeS.userbase
    },
    checkedNames() {
      return this.checked.join(', ')
    },
  },
  mounted() {
    
  },
};
</script>

<style scoped>
.arrow_back {
  margin-bottom: 10px;
}
</style>