<template>

    <tablecustom 
        @search="searchB" 
        :objParams="objParams"
        :columns="columns" 
        :rows="filteredItems"
        @open="open"
    />

    <viewbox 
        v-if="modalShowview == true" 
        @close="modalShowview = false" 
        :obj="this.objCard" 
    ></viewbox>

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/newtickets/view/index.vue'
import { Tickets } from '@/API.js'
import { storeS } from '@/store.js'
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { mutateTypeTicket, 
        mutateWayproblem,
        mutateStatusTicket,
        mutatePriorityTicket,
        mutateDecidedStatusTicket
      } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Tickets();

export default{
    props: ['obj'],
    components: { 
        tablecustom,
        viewbox 
    },
    data(){
        return{
            form: "",
            search: "",
            modalShowview: false,
            objCard: '',
            objParams:{
                search: ''
            },
            columns: [
                {
                    name: "ID",
                    text: "ticketId",
                    align: "left",
                    status: true
                },
                // {
                //     name: this.$t("ticket"),
                //     text: "type",
                //     align: "left",
                //     mutate: (item) => this.mutateticket(item),
                //     status: true
                // }, нужно вывести тип тикета (проблема или пожелание) todo dev
                {
                    name: this.$t("ticket_name"),
                    text: "ticketName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerId",
                    align: "right",
                    mutate: (item) => this.nameWorker(item),
                    status: true
                },
                {
                    name: this.$t("date"),
                    text: "createDatetime",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "right",
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color,
                    status: true
                },
                {
                    name: this.$t("decidedStatus"),
                    text: "decidedStatus",
                    align: "right",
                    mutate: (item) => this.mutateDecidedStatus(item).name,
                    mutateClass: (item) => this.mutateDecidedStatus(item).color,
                    status: true
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "right",
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color,
                    status: true
                },
                {
                    name: this.$t("clients"),
                    text: "userPhones",
                    align: "right",
                    mutate: (item) => item ? this.mutateNumber(item) : '-',
                    status: true
                },
                
            ],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        // Отримати дані з об'єкта
        getdata(){
            this.form = this.obj
        },
        
        // Відкрити вікно деталей тикета
        open(e){
            apiServe.getTicket(e.ticketId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.modalShowview = true;
                } else {
                }
            })
        },
        
        // Функція пошуку по таблиці
        searchB(e){
            this.objParams.search = e
        },
        
        // Отримати ім'я працівника
        nameWorker(item){
            return nameWorker(item)
        },
        
        // Функція форматування номерів телефонів
        mutateNumber(value){
            console.log(value)
            var r = ''

            value.forEach((el, index) => {
                if(index + 1 === value.length) {
                    r += el.number
                } else {
                    r += el.number + ', '
                }
            })

            console.log(r)

            return r
        },  
        
        // Функція мутації типу тикета
        mutateticket(e){
            return mutateTypeTicket(e)
        },
        
        // Функція мутації способу вирішення проблеми
        mutatewayproblem(e){
            return mutateWayproblem(e)
        },
        
        // Функція мутації статусу тикета
        mutatestatus(e){
            return mutateStatusTicket(e)
        },
        
        // Функція мутації пріоритету тикета
        mutatepriority(e){
            return mutatePriorityTicket(e)
        },
        
        // Функція мутації статусу вирішення тикета
        mutateDecidedStatus(e){
            return mutateDecidedStatusTicket(e)
        },
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.objParams.search == '' || item.ticketName.toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1  ;
                })
        },
        workers() {
            return storeS.workers
        },
    }
}
</script>