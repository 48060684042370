<template>
    <modal :title="$t('desc')" >
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <template v-if="this.user.userid != 34">
                <b-card>
                    <b-row>
                        <b-col>
                            <div class="mb-3">
                                <h4 class="fs-15">{{ $t('version') }}</h4>
                                <input type="text" class="form-control" id="contactNumber" v-model="form.version" >
                            </div>
                        </b-col>
                        <b-col>
                            <div class="mb-3">
                                <h4 class="fs-15">{{ $t('Deadline') }}</h4>
                                <flat-pickr
                                    v-model="form.deadlineDate"
                                    :config="config"
                                    class="form-control"
                                ></flat-pickr>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card>
                    <h4 class="text-center"><b>{{$t('desc')}}</b></h4>
                    <QuillEditor v-model:content="form.comment" theme="snow" contentType="html" toolbar="full"/>
                </b-card>
            </template>
            <template v-if="this.user.userid == 34">
                <b-card>
                    <b-row>
                        <b-col>
                            <div class="mb-3">
                                <h4 class="fs-15">{{ $t('version') }}</h4>
                                <input type="text" class="form-control" id="contactNumber" v-model="form.version" >
                            </div>
                        </b-col>
                        <b-col>
                            <div class="mb-3">
                                <h4 class="fs-15">{{ $t('Deadline') }}</h4>
                                <flat-pickr
                                    v-model="form.deadlineDate"
                                    :config="config"
                                    class="form-control"
                                ></flat-pickr>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card>
                    <h4 class="text-left"><b>{{$t('desc')}}</b></h4>
                    <hr />
                    
                    <div class="mb-4">
                        <h4 class="text-left"><b>🔥 Нові можливості: </b></h4>
                        <div class="flex-grow-1 ms-2 mb-2 mt-3" v-if="this.descForm.new_opportunities.length == 0">
                            <!-- <div class="input-group">
                                <input type="text" v-model="item.desc" class="form-control" >
                                <button @click="addElement(item)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                            </div> -->
                            <div class="input-group">
                                <input type="text" v-model="newOpportunity" class="form-control">
                                <button @click="addElement(descForm.new_opportunities, newOpportunity)" class="btn btn-success" type="button">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                        <ul class="list-unstyled vstack gap-3 mb-0">
                            <li v-for="(input, index) in this.descForm.new_opportunities" :key="index">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-dark text-black">#{{index+1}}</div></div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <!-- <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.desc" v-on:keyup.enter="addElement(index)">
                                            <button @click="addElement(input,index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                            <button @click="addElement(input,index)" class="btn btn-info" type="button"><i class="ri-pencil-line"></i></button>
                                            <button @click="removeElement(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                        </div> -->
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.desc">
                                            <button @click="addElement(descForm.new_opportunities, input.desc, index)" class="btn btn-success" type="button">
                                                <i class="ri-add-line"></i>
                                            </button>
                                            <button @click="showEditModal = true" class="btn btn-info" type="button"><i class="ri-pencil-line"></i></button>
                                            <button @click="removeElement(descForm.new_opportunities, index)" class="btn btn-danger" type="button">
                                                <i class="ri-delete-bin-2-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="mb-4">
                        <h4 class="text-left"><b>🙌 Покращено: </b></h4>
                        <div class="flex-grow-1 ms-2 mb-2 mt-3" v-if="this.descForm.improved.length == 0">
                            <!-- <div class="input-group">
                                <input type="text" v-model="item.desc" class="form-control" >
                                <button @click="addElement(item)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                            </div> -->
                            <div class="input-group">
                                <input type="text" v-model="newImprovement" class="form-control">
                                <button @click="addElement(descForm.improved, newImprovement)" class="btn btn-success" type="button">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                        <ul class="list-unstyled vstack gap-3 mb-0">
                            <li v-for="(input, index) in this.descForm.improved" :key="index">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-dark text-black">#{{index+1}}</div></div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <!-- <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.desc" v-on:keyup.enter="addElement(index)">
                                            <button @click="addElement(input,index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                            <button @click="addElement(input,index)" class="btn btn-info" type="button"><i class="ri-pencil-line"></i></button>
                                            <button @click="removeElement(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                        </div> -->
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.desc">
                                            <button @click="addElement(descForm.improved, input.desc, index)" class="btn btn-success" type="button">
                                                <i class="ri-add-line"></i>
                                            </button>
                                            <button class="btn btn-info" type="button"><i class="ri-pencil-line"></i></button>
                                            <button @click="removeElement(descForm.improved, index)" class="btn btn-danger" type="button">
                                                <i class="ri-delete-bin-2-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="mb-4">
                        <h4 class="text-left"><b>💪 Виправлено: </b></h4>
                        <div class="flex-grow-1 ms-2 mb-2 mt-3" v-if="this.descForm.corrected.length == 0">
                            <!-- <div class="input-group">
                                <input type="text" v-model="item.desc" class="form-control" >
                                <button @click="addElement(item)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                            </div> -->
                            <div class="input-group">
                                <input type="text" v-model="newCorrected" class="form-control">
                                <button @click="addElement(descForm.corrected, newCorrected)" class="btn btn-success" type="button">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                        <ul class="list-unstyled vstack gap-3 mb-0">
                            <li v-for="(input, index) in this.descForm.corrected" :key="index">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-dark text-black">#{{index+1}}</div></div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <!-- <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.desc" v-on:keyup.enter="addElement(index)">
                                            <button @click="addElement(input,index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                            <button @click="addElement(input,index)" class="btn btn-info" type="button"><i class="ri-pencil-line"></i></button>
                                            <button @click="removeElement(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                        </div> -->
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.desc">
                                            <button @click="addElement(descForm.corrected, input.desc, index)" class="btn btn-success" type="button">
                                                <i class="ri-add-line"></i>
                                            </button>
                                            <button class="btn btn-info" type="button"><i class="ri-pencil-line"></i></button>
                                            <button @click="removeElement(descForm.corrected, index)" class="btn btn-danger" type="button">
                                                <i class="ri-delete-bin-2-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </b-card>
            </template>
        </template>
        <template v-slot:footer-bottom>
            <!-- <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</a> -->
            <b-button @click="add()" variant="success" class="waves-effect waves-light"><i class="align-bottom me-1"></i>{{ $t('Edit') }}</b-button>
        </template>
    </modal>

    <!-- Модальне вікно з деталями по пункту -->
    <editElement 
        v-if="showEditModal"
        @close="showEditModal = false"
        :objCard="this.obj"
    />

</template>

<script>
import modal from '@/components/modal/modal-lg'
import editElement from './editStep/index'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import { Updates } from '@/API.js'
import { storeS } from '@/store';

let apiServe = new Updates();

export default{
    props: ['obj'],
    components: { 
        modal,
        flatPickr,
        editElement
    },
    data(){
        return{
            form: {
                comment: ""
            },
            newOpportunity: '',
            newImprovement: '',
            newCorrected: '',
            descForm: {
                new_opportunities: [],
                improved: [],
                corrected: []
            },
            showEditModal: false,
            editElement: null,
            editIndex: null,
            item: {
                desc: '',
                checked: 0
            },
            config: {
                mode: "single",
                minDate: "today",
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: ""
            },
        }
    },
    created(){
        this.form = this.obj
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
    },
    methods: {
        add(){
            var editForm = {
                comment: this.form.comment,
                deadlineDate: this.form.deadlineDate,
                version: this.form.version
            }

            apiServe.editUpdate(this.form.deadlineId, editForm).then(res => {
                if(res){
                    this.$toast.success(this.$t('changeswereSuccessful'));
                    this.$emit('close')
                    this.eventBus.emit('saveUpdate', true)
                }
            })
        },
        addElement(array, desc, index = null) {
            if (index !== null) {
                array.splice(index + 1, 0, {
                    desc: desc,
                    checked: 0
                });
            } else {
                array.push({
                    desc: desc,
                    checked: 0
                });
            }
        },
        removeElement(array, index) {
            array.splice(index, 1);
        }
        // addElement(e, index) {
        //     if(this.descForm.new_opportunities.length > 0){
        //         this.descForm.new_opportunities.splice(index+1, 0, {
        //             desc: "",
        //             checked: 0
        //         });
        //     } else {
        //         this.descForm.new_opportunities.push({
        //             desc: e.desc,
        //             checked: 0
        //         })
        //     }
        // },
        // removeElement (index) {
        //     this.descForm.new_opportunities.splice(index, 1)
        // },
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
}
</script>