<template>
    <modal :title="$t('Updates')" @close="$emit('close')" :ifPrevopen="ifPrevopen" :key="this.componentKey" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen">
        <template v-slot:head-button>
            <div class="dropdown" v-if="perms[421]">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item" v-if="perms[421]" @click="createEvent">{{ $t('addToCalendar') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col>
                    <b-card>
                        <div class="d-flex">
                            <div class="flex-grow-1 click" >
                                <h4><b>{{form.update}}</b> <span :class="`fs-14 ${mutateStatus(form.status).color}`">{{ mutateStatus(form.status).name }}</span></h4>
                                <p class="text-muted mb-0"><b>{{ $t('version') }}</b>: {{form.version}}</p>
                                <p class="text-muted"><b>{{ $t('deadLine') }}</b>: {{form.deadlineDate}}</p>
                            </div>
                            <div class="flex-shrink-0" v-if="(perms[372] || perms[2000]) && this.form.createWorkerId == this.user.userid">
                                <div>
                                    <button
                                        v-if="(perms[372] || perms[2000]) && this.form.createWorkerId == this.user.userid"
                                        @click="showModal = true"
                                        class="btn btn-light"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                    >
                                    <i class="ri-pencil-fill align-bottom"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="ql-container">
                            <div class="ql-editor" v-html="form.comment">
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div>
                        <b-tabs pills >
                            <b-tab v-if="this.form.projects.length > 0">
                                <template #title>
                                    <span class="d-block d-sm-none"><i class="ri-task-line"></i></span>
                                    <span class="d-none d-sm-block">{{ $t('navProjects') }}</span> 
                                </template>
                                <div class="mt-1"><projects :obj="this.form.projects" /> </div>
                            </b-tab>
                            <b-tab v-if="this.form.tickets.length > 0" >
                                <template #title>
                                    <span class="d-block d-sm-none"><i class="ri-task-line"></i></span>
                                    <span class="d-none d-sm-block">{{ $t('tickets') }}</span> 
                                </template>
                                <div class="mt-1"><tickets :obj="this.form.tickets" /> </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-success fw-medium"  @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</button> -->
            <button 
                type="button" 
                v-if="perms[373] || perms[2000]"
                @click="dialogShow = true" 
                class="btn btn-danger r waves-effect waves-light">
                {{ $t('Delete') }}
            </button>
            <button class="btn btn-warning r waves-effect waves-light" 
                @click="this.startEdit('projects')"
                v-if="(perms[2000] || perms[372]) && this.form.createWorkerId == this.user.userid && this.form.status != 'pre_release'"
            >
                {{ $t('pre_release') }}
            </button>
            <button class="btn btn-success r waves-effect waves-light" 
                @click="goInRelease"
                v-if="(perms[2000] || perms[372]) && this.form.createWorkerId == this.user.userid && this.form.status == 'pre_release'"
            >
                {{ $t('inRelease') }}
            </button>
        </template>
    </modal>

    <editdesc v-if="showModal" @close="showModal = false" :obj="this.form" />

    <!-- событие в календарь -->
    <addEvent
        v-if="showAddEvent"
        @close="showAddEvent = false"
        :crItem="crItem"
        @updateCard="updateCard"
    />
    <!-- событие в календарь -->

    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="toDelete()" 
    />

    <editWindow v-if="this.showEdit == true" @close="this.showEdit = false" :items="this.items" :itemsTickets="itemsTickets" :objdata="this.rows" :id="form.deadlineId" :form="this.form" @getdata="this.updateCard(); this.$emit('getdata')"></editWindow>
</template>

<script>
import editWindow from '../delcategmodal.vue'
import modal from '@/components/modal/modal-lg'
import editdesc from './editdesc'
import projects from './projects'
import tickets from './tickets.vue';
import addEvent from '@/components/calendar/addEvent'
import modalremove from '@/components/modal/modalremove'
import { Updates } from '@/API.js'
import { storeS } from '@/store';
import { mutateUpdate, mutateStatusUpdates } from '@/usabilityScripts/globalMutate.js'


let apiUpdates = new Updates();

export default{
    props: ['objCard', 'ifPrevopen', 'ifNextopen', 'rows'],
    components: { 
        modal, 
        projects, 
        tickets, 
        editdesc,
        addEvent,
        editWindow,
        modalremove
    },
    data(){
        return{
            componentKey: 0,
            dialogShow: false,
            items: [],
            itemsTickets: [],
            showEdit: false,
            showAddEvent: false,
            crItem: {
                id: '',
                type: 'deadline',
                name: '',
                description: '',
                date: '',
                time: ''
            },
            form:"",
            showModal: "",
            boxprojects: false,
            boxtickets: false,
            projects:{},
            tickets: {},
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        goInRelease() {
            let obj ={
                status: "in_release"
            }
            apiUpdates.deadlineStatus(this.form.deadlineId, obj).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('success'))
                    this.$emit('getdata')
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        updateCard(){
            apiUpdates.getUpdate(this.form.deadlineId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data
                    this.componentKey++
                }
            })
        },
        startEdit(a) {
            // if(a == 'tickets') {
            //     this.items = this.form.tickets
            // } else {
            //     this.items = this.form.projects
            // }
            this.items = this.form.projects;
            this.itemsTickets = this.form.tickets;
            this.showEdit = true
        },
        getdata(){
            this.form = this.objCard
        },
        toDelete(){
            apiUpdates.deleteUpdate(this.form.deadlineId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'));
                    this.eventBus.emit('saveUpdate', true)
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        prevOpen(){
            this.$emit('close');
            this.$emit('prevOpen', this.form.deadlineId)
        },
        nextOpen(){
            this.$emit('close');
            this.$emit('nextOpen', this.form.deadlineId)
        },
        createEvent(){
            this.crItem.date = this.form.deadlineDate;
            this.crItem.time = '10:00';
            this.crItem.name = this.mutateUpdate(this.form.update);
            this.crItem.description = '';
            this.showAddEvent = true;
        },
        mutateUpdate(e){
            return mutateUpdate(e)
        },
        mutateStatus(item) {
            return mutateStatusUpdates(item)
        },
        // getProjects(){
        //     apiArchive.getDeadlineProjects(this.form.id).then(res => {
        //         if(res){
        //             this.form.projects = res;
        //         }
        //     })
        // },
        // getTickets(){
        //     apiArchive.getDeadlineTickets(this.form.id).then(res => {
        //         if(res){
        //             this.tickets = res;
        //         }
        //     })
        // }
    },
    mounted(){
        this.eventBus.on('saveProject', (status) => {
            if(status == true) {
                this.getdata();
            }
        }),
        this.eventBus.on('saveTicket', (status) => {
            if(status == true) {
                this.getTickets();
            }
        })
    },
    computed: {
        perms(){
            return storeS.perms
        },
        user(){
            return storeS.userbase
        }
    }
}
</script>