<template>
    <!-- Вікно редагування інформації до пункту в оновленнях -->

    <modal :title="$t('desc')" @close="$emit('close')" >

        <template v-slot:body>

            <b-card>
                <!-- <b-row> -->
                    <b-col lg="6">
                        <h5 class="fs-14 mb-3 text-black fw-semibold">{{ $t('Testing') }}</h5>

                        <div class="mb-3">

                            <label class="form-label">{{ $t('HasSolutionToticketBeenTested') }}</label>

                            <!-- Вибір так чи ні (чи проводилось тестування) -->
                            <div>
                                <b-form-radio-group
                                    v-model="form.passedTest"
                                    :options="optionsBeforeProblem"
                                    class="mb-3"
                                    value-field="item"
                                    text-field="name"
                                    disabled-field="notEnabled"
                                ></b-form-radio-group>
                            </div>

                            <!-- Якщо проводилось -->
                            <div class="mb-3 mt-2" v-if="this.form.passedTest == true">

                                <label class="form-label">{{ $t('WhoConductedtheTesting') }}?</label> <br/>

                                <!-- Ким пройшло тестування (вибір співробітників) -->
                                <template v-for="item in qaWorkerList" :key="item"> 
                                    <span 
                                        :class="`fs-12 badge bg-${checkWorker(item) ? 'danger' : 'success'} copy`" 
                                        @click="checkWorker(item) ? '' : addWorkers(item)"
                                    >
                                        {{ item.label }}
                                    </span>
                                </template>
                                
                                <Multiselect 
                                    class="mt-3"
                                    v-model="form.whoPassedTest"
                                    :close-on-select="true"
                                    :searchable="true"
                                    mode="tags"
                                    :options="this.workersList"
                                    :placeholder="$t('enterSearchTerm')"
                                />

                            </div>

                            <div class="mb-3 mt-2" v-if="this.form.passedTest == false">
                                <b-alert show variant="danger">
                                    {{ $t('decision_dangerAlert') }}
                                    <br/>
                                    <span class="fw-semibold"> {{ $t('decision_dangerAlert_pm') }}</span>
                                </b-alert>
                            </div>

                        </div>
                    </b-col>
                    <b-col lg="6">
                        <h5 class="fs-14 mb-3 text-black fw-semibold">{{ $t('tickets') }}</h5>

                        <div class="mb-3">

                            <label class="form-label">{{ $t("Пункт оновлення пов'язаний з тікетом") }}?</label>

                            <!-- Вибір так чи ні (чи є тікети пов'язані з пунктом) -->
                            <div>
                                <b-form-radio-group
                                    v-model="form.withTickets"
                                    :options="optionsBeforeProblem"
                                    class="mb-3"
                                    value-field="item"
                                    text-field="name"
                                    disabled-field="notEnabled"
                                ></b-form-radio-group>
                            </div>

                            <!-- Якщо є -->
                            <div class="mb-3 mt-2" v-if="this.form.withTickets == true">

                                <label class="form-label">{{ $t('Оберіть тікети') }}</label>

                                <Multiselect 
                                    class="mt-1"
                                    v-model="form.listTickets"
                                    :close-on-select="true"
                                    :searchable="true"
                                    mode="tags"
                                    :object="true"
                                    :options="this.ticketsList"
                                    :placeholder="$t('enterSearchTerm')"
                                />

                            </div>

                        </div>

                    </b-col>

                    <b-col lg="12">
                        <h4 class="text-left"><b>{{$t('desc')}}</b></h4>
                        <QuillEditor v-model:content="form.comment" theme="snow" contentType="html" toolbar="full"/>
                    </b-col>
                <!-- </b-row> -->
            </b-card>
            
            <b-card>
                <files 
                    :obj="files" 
                    :objFiles="files" 
                    @uploadFiles="uploadFiles"
                    @downloadFile="downloadFile"
                    @deleteFile="deleteFile"
                    @getFile="getFile"
                />
                <!-- <modallg v-if="showImg" @close="showImg = false">
                    <template v-slot:body>
                        <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                            <img  :src="`${gFile.src}`" style="">
                        </template>
                        <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                            <video controls>
                                <source :src="`${gFile.src}`" type="video/mp4">
                            </video>
                        </template>
                    </template>
                    <template v-slot:buttons>
                        <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
                        <button class="gdelete gbtn" @click="showRemDialog(gFile)" ><i class="ri-delete-bin-fill"></i></button>
                        <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
                        <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
                    </template>
                </modallg>

                <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.deleteFile(gFile)" /> -->
            </b-card>
        </template>
        <template v-slot:footer-bottom>
            <!-- <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</a> -->
            <b-button @click="add()" variant="success" class="waves-effect waves-light"><i class="align-bottom me-1"></i>{{ $t('Edit') }}</b-button>
        </template>
    </modal>
</template>

<script >
import modal from '@/components/modal/modal-lg'
import files from '@/components/files/index'
import modallg from '@/components/modal/img'
import modalremove from '@/components/modal/modalremove'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store'; // глобальне сховище даних

export default {
    // props: ['objCard'],
    props: ['objCard'],
    components: {
        modal,
        files,
        modallg,
        modalremove,
        Multiselect
    },
    data() {
        return {
            form: {
                comment: "",
                passedTest: false,
                whoPassedTest: [], // ким пройшло тестування
                withTickets: false, // чи є тікети до пункту
                listTickets: [] // список тікетів
            },
            files: [],
            optionsBeforeProblem: [ // опції до чи була відома проблема
                { item: true, name: this.$t('y') },
                { item: false, name: this.$t('n') },
            ],
            workersList: [{}], // список співробітників
            qaWorkerList: [], // список qa
            ticketsList: [] // список тікетів
        }
    },
    created() {
         // Заповнення списку співробітників для вибору в формі
         for(var item in this.workers){
            this.workersList.push({
                label: this.workers[item].workerName,
                value: this.workers[item].workerId,
            })
        }

        // Заповнення списку співробітників для вибору в формі (QA)
        for(var item in this.workers){
            if(this.workers[item].workerJob == "tester") {
                this.qaWorkerList.push({
                    label: this.workers[item].workerName,
                    value: this.workers[item].workerId,
                })
            }
        }

        console.log("obj", this);

        if(this.objCard.tickets.length > 0) {
            for(var item in this.objCard.tickets){
                this.ticketsList.push({
                    label: `${this.objCard.tickets[item].ticketId}` + " - " + `${this.objCard.tickets[item].ticketName}`,
                    value: this.objCard.tickets[item].ticketId
                })
            }
        }
        
    },
    methods: {
        addWorkers(e){
            // Швидке додавання співробітника за межами списку в масив тестерів рішення
            this.form.whoPassedTest.push(e.value)
        },
        checkWorker(e){
            // перевірка наявності співробітника в списку обраних співробітників
            return this.form.whoPassedTest.includes(e.value);
        },
    },
    computed: {
        dealines(){
            /*
                Повертає дані оновлень з глобального стору.
            */
            return storeS.dealines
        },
        workers() {
            /*
                Повертає список з усіма користувачами з глобального стору.
            */
            return storeS.workers
        },
    }
}
</script>
