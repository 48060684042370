<template>
    <div class="mt-1">
        <main class = 'main_section'>
            <div style="background-color: white; border-radius: 7px; padding: 20px; margin-top: 10px;">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" style="width: calc(100%/10);">#</th>
                            <th scope="col" style="width: calc(100% - ((100%/10)*7)); text-align: center;">{{ this.$t('name') }}</th>
                            <th scope="col" style="width: calc(100% - ((100%/10)*6)); text-align: center;">{{ this.$t('action') }}</th>
                            <th scope="col" style="width: calc(100% - ((100%/10)*8));  text-align: center;">{{ this.$t('transferedTo') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.itemsTickets" :key="index" :class="this.delete.indexOf(item) != -1 ? 'table-warning' : (this.transfer.indexOf(item) != -1 || this.transfer.find(el => el.item == item) ? 'table-success' : '' )">
                            <th scope="row" style="text-align: center;">{{ index }}</th>
                            <td style="text-align: center;">{{ item.projectName ? item.projectName : item.ticketName}}</td>
                            <td style="text-align: center; display: grid; align-items: center; justify-content: center;">
                                <select @input="addGroup($event, item)" class="form-select" aria-label="Default select example" v-if="this.transfer.indexOf(item) != -1 || this.transfer.find(el => el.item == item) ">
                                    <option value="null">{{ this.$t('Null') }}</option>
                                    <option v-for="items in this.list" :value="'' + items.value">{{ items.label }}</option>
                                </select>
                                <div><p></p></div>
                                <button type="button" class="btn btn-warning" style="margin-bottom: 5px;" @click="addInDelete(item)">{{ this.$t('pre_release') }}</button>
                                <button type="button" class="btn btn-success" @click="addInTransfer(item, index)">{{this.$t('transfer')}}</button>
                            </td>
                            <td style="text-align: center;">
                                {{ this.transfer.indexOf(item) != -1  || this.transfer.find(el => el.item == item) ? (this.list.find(el => el.value == item.deadlineId))?.label : (this.delete.indexOf(item) != -1 ? this.$t('pre_release') : '' )}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    </div>
    <div class="m-2">
        <button type="button" class="btn btn-info" @click="checkall()" style="margin-right: 20px;">{{ this.$t('takeAll') }}</button> 
        <button class="btn btn-success r waves-effect waves-light" @click="this.$emit('boxTicketsRel', this.transfer, this.delete)" v-if="this.delete.length + this.transfer.length == this.itemsTickets.length">{{ this.$t('done') }}</button>
    </div>
</template>

<script>
export default {
    data() {
        return{
            itemsTickets: [],
            delete: [],
            transfer: [],
            list: [],
        }
    },
    props: ['tickets', 'child', 'form'],
    created() {
        this.child.forEach(element => {
            this.list.push({
                label: `${this.$t('app')}: ` + element.update.charAt(0).toUpperCase() + element.update.slice(1) + ` | ${this.$t('version')}: ` + element.version + ` | ${this.$t('date')}: ` + element.deadlineDate,
                value: element.deadlineId
            })
        });
        this.itemsTickets = this.tickets
    },
    methods: {
        checkall() {
            console.log(this.itemsTickets)
            this.itemsTickets.forEach(el => {
                if(this.delete.indexOf(el) == -1 && this.transfer.indexOf(el) == -1) {
                    this.delete.push(el)
                }
            })
        },
        addGroup(e, item) {
            console.log(e, item)
            this.transfer[this.transfer.indexOf(this.transfer.find(el => el.ticketId == item.ticketId))].deadlineId = Number(e.target.value)
        },
        addInDelete(e){
            if(this.delete.indexOf(e) == -1) {
                this.delete.push(e)
            } else {
                return
            }
            if(this.transfer.find(el => e[Object.keys(e)[0]] == el[Object.keys(el)[0]])) {
                this.transfer.splice(this.transfer.indexOf(e), 1)
            } else if(this.transfer.find(el => e[Object.keys(e)[0]] == el.item[Object.keys(el.item)[0]])) {
                this.transfer.splice(this.transfer.indexOf(this.transfer.find(el => e[Object.keys(e)[0]] == el.item[Object.keys(el.item)[0]])), 1)
            }
        },
        addInTransfer(e) {
            if(this.transfer.indexOf(e) == -1) {
                this.transfer.push(e)
            } else {
                return
            }
            if(this.delete.indexOf(e) != -1) {
                this.delete.splice(this.delete.indexOf(e), 1)
            }
        },
    }
}
</script>

<style scoped>

</style>