<template>

    <tablecustom 
        @search="searchB" 
        :objParams="objParams"
        :columns="columns" 
        :rows="filteredItems" 
        @open="open"
    />

    <viewbox 
        v-if="modalShowview == true" 
        @close="modalShowview = false" 
        :obj="this.objCard"
    >
    </viewbox>

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/projects/view/index.vue'
import { Archive } from '@/API.js'
import { mutateStatusProject } from '../../../usabilityScripts/globalMutate';

let apiArchive = new Archive();

export default{
    props: ['obj'],
    components: { 
        tablecustom, 
        viewbox 
    },
    data(){
        return{
            form: "",
            modalShowview: false,
            objCard: "",
            objParams: {
                search: ''
            },
            columns: [
                {
                    name: this.$t('projectTitle'),
                    text: "projectName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("projectLead"),
                    text: 'teamId',
                    align: "left",
                    mutate: (item) => item != null ? item : '-',
                    status: true
                },
                // {
                //     name: this.$t("Progress"),
                //     text: "progressBar",
                //     align: "right",
                //     status: true
                // },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "right",
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color,
                    status: true
                }
            ],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj
        },
        // toedit(e){
        //     apiArchive.editProject(e.id, e).then(res => {
        //         if(res){
        //             this.eventBus.emit('saveProject', true)
        //             this.$toast.success(this.$t('changeswereSuccessful'));
        //         }
        //     })
        // },
        open(e){
            apiArchive.getArchiveProject(e.projectId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.modalShowview = true;
                }
            })
        },
        searchB(e){
            this.objParams.search = e
        },
        mutatestatus(e) {
            return mutateStatusProject(e)
        }
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.form)
                // По полю поиска
                .filter(item => {
                    return this.objParams.search == '' || item.projectName.toLowerCase().indexOf(this.objParams.search.toLowerCase()) !== -1  ;
                })
        },
    }
}
</script>